/* global CONFIG */
import logger from 'debug';
import { uniq } from 'lodash';

async function addToCache(urls) {
  const myCache = await window.caches.open('image-cache');
  await myCache.addAll(urls);
}

// NB if images are already in workbox cache, those are used
const precacheContainerImages = async (profile) => {
  if (!profile) return;

  const paths = profile.containers.map(
    container => container.images.map(image => image.url),
  ).flat();

  try {
    await addToCache(paths.map(path => `${CONFIG.imageUrl}${path}`));
  } catch (error) {
    logger('app:error')('Error pre-caching container resources: ', error);
  }
};

const precacheAllImages = async (appSettings) => {
  // get all container images in all profiles
  const paths = uniq(appSettings.profiles.map(
    profile => profile.containers.map(
      container => container.images.map(image => image.url),
    ).flat(),
  ).flat());

  const pages = [
    '/privacy',
    '/privacyPolicy',
    '/termsOfUse',
    '/help',
    '/kindOfWaste',
    '/whyTossed',
    '/destination',
    '/container',
    '/quantity',
    '/exactQuantity',
    '/typeOfWaste',
    '/summary',
  ];

  try {
    await addToCache(paths.map(path => `${CONFIG.imageUrl}${path}`));
    await addToCache(pages.map(path => `${CONFIG.publicUrl}${path}`));
    await addToCache([`${CONFIG.publicUrl}/img/calculator.png`]);
  } catch (error) {
    logger('app:error')('Error pre-caching container resources: ', error);
  }
};

export { precacheAllImages, precacheContainerImages };
