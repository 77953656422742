/* global APP_INFO */
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Router, Redirect } from '@reach/router';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import configureStore from './store';
import reducer from './reducers';
import mainSaga from './sagas';
import Home from './Home';
import Help from './Help';
import Settings from './Settings';
import Privacy from './Privacy';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';
import KindOfWaste from './KindOfWaste';
import WhyTossed from './WhyTossed';
import Destination from './Destination';
import Container from './Container';
import Quantity from './Quantity';
import ExactQuantity from './ExactQuantity';
import TypeOfWaste from './TypeOfWaste';
import Summary from './Summary';
import Pin from './Pin';
import i18n from './i18n';
import App from './App';
import registerSW from './serviceWorker';

// enable console debugging
if (process.env.NODE_ENV !== 'production') {
  localStorage.setItem('debug', 'app:*,sw:*');
}

const start = () => {
  const { store, persistor } = configureStore({ reducer, mainSaga });

  registerSW();

  const bugsnagClient = bugsnag({
    apiKey: 'aabd8e97621ff61823e588d2020199c9',
    releaseStage: process.env.NODE_ENV, // local|dev|staging|production
    notifyReleaseStages: ['production', 'staging'], // don't report in dev/local mode
    appVersion: APP_INFO.version,
  });
  bugsnagClient.use(bugsnagReact, React);
  const ErrorBoundary = bugsnagClient.getPlugin('react');

  render(
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate
          loading={null}
          persistor={persistor}
        >
          <App>
            <Router>
              {/* remember to add paths to service-worker for caching */}
              <Redirect from="/" to="app" noThrow />
              <Home path="/app" default />
              <Settings path="/settings" />
              <Pin path="/pin" />
              <Privacy path="/privacy" />
              <PrivacyPolicy path ="/privacyPolicy"/>
              <TermsOfUse path = "termsOfUse"/>
              <Help path="/help" />
              <KindOfWaste path="/kindOfWaste" />
              <WhyTossed path="/whyTossed" />
              <Destination path="/destination" />
              <Container path="/container" />
              <Quantity path="/quantity" />
              <ExactQuantity path="/exactQuantity" />
              <TypeOfWaste path="/typeOfWaste" />
              <Summary path="/summary" />
            </Router>
          </App>
        </PersistGate>
      </Provider>
    </ErrorBoundary>,
    document.getElementById('root'),
  );
};

i18n(start);
