/* global APP_INFO */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import GavelIcon from '@material-ui/icons/Gavel';
import HomeIcon from '@material-ui/icons/Home';
import HelpIcon from '@material-ui/icons/Help';
import ReplayIcon from '@material-ui/icons/Replay';
import CachedIcon from '@material-ui/icons/Cached';
import moment from 'moment';
import {Location, navigate} from '@reach/router';
import {useTranslation} from 'react-i18next';
import {includes} from 'lodash';
import fetchWaitingToSyncCount from '~/App/sync';

const useStyles = makeStyles(() => ({
  listContainer: {
    width: 250,
    height: '100%',
  },
  list: {
    height: '100%',
  },
  appVersion: {
    position: 'absolute',
    bottom: 105,
    left: 0,
    width: '100%',
  },
  copyRight: {
    position: 'absolute',
    bottom: 0,
    left: 15,
    width: '100%',
    fontSize: '12px',
    textAlign: 'left',
    padding: '5px 0',
  },
  alignedListItem: {
    marginLeft: '-3px',
  },
}));

const Sidebar = ({lastSyncDate, toggleDrawer, open}) => {
  const classes = useStyles();
  const [offline, setOffline] = useState(true);
  const {t, i18n} = useTranslation('sidebar');
  const [waitingToSyncCount, setWaitingToSyncCount] = React.useState(0);

  // only show settings for certain paths
  const settingsEnabledPaths = [
    '/app',
    '/privacy',
    '/privacyPolicy',
    '/termsOfUse',
    '/settings',
    '/help',
  ];
  const showSettings = (path) => includes(settingsEnabledPaths, path);

  // update sync count
  fetchWaitingToSyncCount(setWaitingToSyncCount);

  const handleConnectionChange = (event) => {
    setOffline(event.type !== 'online');
  };

  const [pageLocation, setPageLocation] = useState();
  React.useEffect(() => {
    setPageLocation('WasteNotGlobal-App');
  }, []);

  React.useEffect(() => {
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    if (navigator.onLine) {
      setOffline(false);
    }

    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  });

  return (
    <Drawer open={open} onClose={toggleDrawer(false)}>
      <div
        className={classes.listContainer}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List className={classes.list}>
          <ListItem button key={t('home')} onClick={() => navigate('/app')}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={t('home')} />
          </ListItem>
          <Location>
            {({location}) =>
              showSettings(location.pathname) && (
                <ListItem button key={t('settings')} onClick={() => navigate('/settings')}>
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('settings')} />
                </ListItem>
              )
            }
          </Location>

          <ListItem button key={t('help')} onClick={() => navigate('/help')}>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary={t('help')} />
          </ListItem>
          <Divider />
          <ListItem
            button
            key={t('privacyPolicy')}
            onClick={() => navigate('/privacyPolicy')}
            className={classes.alignedListItem}
          >
            <ListItemText primary={t('privacyPolicy')} />
          </ListItem>
          <ListItem
            button
            key={t('privacyRequest')}
            className={classes.alignedListItem}
            onClick={() =>
              window.open(
                `https://privacyportal-eu-cdn.onetrust.com/dsarwebform/8394ad8c-2b46-4837-8771-cbc69779a644/31bea1f4-92c3-440b-be0e-468af4f4b1f3.html?Source=${pageLocation}`,
                '_blank',
                'noopener,noreferrer'
              )
            }
          >
            <ListItemText primary={t('privacyRequest')} />
          </ListItem>

          <ListItem
            button
            key={t('termsOfUse')}
            onClick={() => navigate('/termsOfUse')}
            className={classes.alignedListItem}
          >
            <ListItemText primary={t('termsOfUse')} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={t('last_sync')}
              secondary={
                lastSyncDate
                  ? moment
                      .unix(lastSyncDate / 1000)
                      .locale(i18n.language)
                      .format('lll')
                  : t('n/a')
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={t('awaiting_sync')}
              secondary={t('waiting_to_sync_count', {waitingToSyncCount})}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="Retry sync"
                onClick={() => window.dispatchEvent(new Event('REPLAY_REQUESTS'))}
                disabled={waitingToSyncCount === 0}
              >
                <ReplayIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary={t('reload')} />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="Reload app"
                onClick={() => document.location.reload()}
                disabled={offline || waitingToSyncCount > 0}
              >
                <CachedIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          {
            // keep as last item, positioned to bottom
          }
          <ListItem className={classes.appVersion}>
            <ListItemText primary={`Version ${APP_INFO.version} Build ${APP_INFO.buildNumber}`} />
          </ListItem>
          {
            // Copyright text positioned at the very bottom
          }

          <ListItem className={classes.copyRight}>
            <ListItemText
              primary= {t('copyRightInfo')}
              secondary={
                <>
                <div>{t('bamcoInfo')}</div> 
                  <div>{t('allRightsReserved')}</div>
                </>
              }
              style={{ fontSize: '12px' }}

            />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

Sidebar.defaultProps = {
  lastSyncDate: 0,
  open: false,
};

Sidebar.propTypes = {
  lastSyncDate: PropTypes.number,
  toggleDrawer: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default Sidebar;
